<template>
  <Modal ref="modal" size="wide">
    <Activity :id="id" :key="key" :type="type" />
  </Modal>
</template>

<script setup>
import Activity from "~/components/Activity.vue";

const modal = ref(null);
const key = ref(Math.random().toString());
const id = ref(null);
const type = ref(null);
defineExpose({
  open,
});

function open(newId, newType) {
  id.value = newId;
  type.value = newType;
  key.value = `${id.value}-${type.value}-${Math.random()}`;
  modal.value.open();
}
</script>
