<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <div class="sidenav-tab-outer">
      <div class="d-inline-flex f-small sidenav-tab">
        <div @click="activeSidenavTab = 1" class="d-flex align-items-center gap-2 flex-grow-1" :class="{ active: activeSidenavTab === 1 }">
          <div class="icon d-inline-flex bg-white align-items-center justify-content-center" :style="{ border: `1px solid #eeeeee`, width: `24px`, height: `24px` }">
            <Icon icon="material-symbols-light:settings-account-box" class="align-items-center justify-content-center" :style="{ fontSize: `.875rem` }" />
          </div>
          <span>Allgemein</span>
        </div>
        <div @click="activeSidenavTab = 2" class="d-flex align-items-center gap-2 flex-grow-1" :class="{ active: activeSidenavTab === 2 }">
          <div class="icon d-inline-flex bg-white align-items-center justify-content-center" :style="{ border: `1px solid #eeeeee`, width: `24px`, height: `24px` }">
            <Icon icon="material-symbols-light:settings-b-roll-outline" class="align-items-center justify-content-center" :style="{ fontSize: `.875rem` }" />
          </div>
          <span>Weitere</span>
        </div>
      </div>
    </div>
    <div class="sidenav-collapse-content">
      <NuxtScrollbar :options="{ wheelSpeed: 0.25, swipeEasing: true, wheelPropagation: false, scrollYMarginOffset: 1 }" tag="ul" class="h-100 navbar-nav navbar-nav-left" :class="{ active: activeSidenavTab === 1 }">
        <li class="nav-item"><Headline class="text-sm mb-0 fw-normal nav-link">Ticketing</Headline></li>
        <li v-if="authStore.hasPermission(['Buchungen Einsehen', 'Buchungen Bearbeiten'])" class="nav-item">
          <SidenavCollapse :link-amount="3" collapse-ref="statistic" :is-expanded="['orders', 'recent-orders', 'event-dates-orders'].includes(getRoute)" nav-text="Buchungen" :class="['orders', 'recent-orders', 'event-dates-orders'].includes(getRoute) ? 'active' : ''">
            <template #icon>
              <Icon icon="material-symbols-light:receipt-rounded" />
            </template>
            <template #list>
              <ul class="nav">
                <SidenavItem to="/orders" text="Übersicht" />
                <SidenavItem to="/recent-orders" text="Meine Buchungen" />
                <SidenavItem v-if="authStore.hasPermission(['Raumbuch Einsehen', 'Raumbuch Bearbeiten'])" to="/event-dates-orders" text="Raumbuch" />
              </ul>
            </template>
          </SidenavCollapse>
        </li>
        <li v-if="authStore.hasPermission(['Termine Einsehen', 'Termine Bearbeiten'])" class="nav-item">
          <SidenavCollapse :link-amount="3" collapse-ref="event-dates" :is-expanded="['event-dates', 'events'].includes(getRoute)" nav-text="Veranstaltungen" :class="['event-dates', 'events'].includes(getRoute) ? 'active' : ''">
            <template #icon>
              <Icon icon="material-symbols-light:event-sharp" />
            </template>
            <template #list>
              <ul class="nav">
                <SidenavItem v-if="authStore.hasPermission(['Termine Einsehen', 'Termine Bearbeiten'])" to="/event-dates" text="Termine" />
                <SidenavItem v-if="authStore.hasPermission(['Termine Einsehen', 'Termine Bearbeiten'])" to="/events" text="Veranstaltungsreihen" />
              </ul>
            </template>
          </SidenavCollapse>
        </li>
        <li
          v-if="client && authStore.hasPermission([
            'Produkte Einsehen',
            'Produkte Bearbeiten',
            'Produktgruppen Bearbeiten',
            'Produktgruppen Bearbeiten',
          ])" class="nav-item"
        >
          <SidenavCollapse :link-amount="2" collapse-ref="products" :is-expanded="getRoute === 'products' || getRoute === 'product-groups'" nav-text="Produkte" :class="getRoute === 'products' || getRoute === 'product-groups' ? 'active' : ''">
            <template #icon>
              <Icon icon="ph:ticket-light" />
            </template>
            <template #list>
              <ul class="nav">
                <SidenavItem v-if="authStore.hasPermission(['Produkte Einsehen', 'Produkte Bearbeiten'])" to="/products" text="Übersicht" />
                <SidenavItem v-if="authStore.hasPermission(['Produktgruppen Einsehen', 'Produktgruppen Bearbeiten'])" to="/product-groups" text="Produktgruppen" />
              </ul>
            </template>
          </SidenavCollapse>
        </li>
        <li
          v-if="authStore.hasPermission([
            'Artikel Einsehen',
            'Artikel Bearbeiten',
            'Artikelgruppen Bearbeiten',
            'Artikelgruppen Bearbeiten',
          ])" class="nav-item"
        >
          <SidenavCollapse :link-amount="2" collapse-ref="articles" :is-expanded="['article-groups', 'articles'].includes(getRoute)" nav-text="Artikel" :class="['article-groups', 'articles'].includes(getRoute) ? 'active' : ''">
            <template #icon>
              <Icon icon="material-symbols-light:format-list-bulleted" />
            </template>
            <template #list>
              <ul class="nav">
                <SidenavItem v-if="authStore.hasPermission(['Artikel Einsehen', 'Artikel Bearbeiten'])" to="/articles" text="Übersicht" />
                <SidenavItem v-if="authStore.hasPermission(['Artikelgruppen Einsehen', 'Artikelgruppen Bearbeiten'])" to="/article-groups" text="Artikelgruppen" />
              </ul>
            </template>
          </SidenavCollapse>
        </li>

        <li
          v-if="authStore.hasPermission([
            'Produkte Einsehen',
            'Produkte Bearbeiten',
            'Artikel Einsehen',
            'Artikel Bearbeiten',
            'Termine Einsehen',
            'Termine Bearbeiten',
            'Raumbuch Einsehen',
          ])" class="nav-item"
        >
          <hr class="mt-2 mb-2 horizontal dark">
        </li>
        <li class="nav-item"><Headline class="text-sm mb-0 fw-normal nav-link">Vetrieb</Headline></li>

        <li v-if="authStore.hasPermission(['Statistik einsehen'])" class="nav-item">
          <SidenavCollapse :link-amount="4" collapse-ref="statistic" :is-expanded="['filters', 'sales-dashboard', 'utilization-dashboard', 'custom-dashboards'].includes(getRoute)" nav-text="Statistiken" :class="['filters', 'sales-dashboard', 'utilization-dashboard', 'custom-dashboards'].includes(getRoute) ? 'active' : ''">
            <template #icon>
              <Icon icon="material-symbols-light:bar-chart" />
            </template>
            <template #list>
              <ul class="nav">
                <SidenavItem v-if="authStore.hasPermission(['Statistik einsehen'])" to="/sales-dashboard" text="Verkaufszahlen" />
                <!--                <SidenavItem v-if="authStore.hasPermission(['Statistik einsehen'])" to="/utilization-dashboard" text="Auslastungszahlen" /> -->
                <SidenavItem v-if="authStore.hasPermission(['Statistik einsehen'])" to="/filters" text="Listen" />
                <SidenavItem v-if="authStore.hasPermission(['Eigene Dashboards'])" to="/custom-dashboards" :text="`Meine Dashboards (${dashboardsCount})`" />
              </ul>
            </template>
          </SidenavCollapse>
        </li>
        <li
          v-if="authStore.hasPermission([
            'Gutscheine Einsehen',
            'Gutscheine Bearbeiten',
            'Aktionen Einsehen',
            'Aktionen Bearbeiten',
          ])"
          class="nav-item"
        >
          <SidenavCollapse
            collapse-ref="promotions"
            :link-amount="4"
            :is-expanded="getRoute === 'promotions' || getRoute === 'promotion-groups' || getRoute === 'code-check' || getRoute === 'vouchers'" nav-text="Aktionen / Gutscheine"
            :class="getRoute === 'promotions' || getRoute === 'promotion-groups' || getRoute === 'code-check' || getRoute === 'vouchers' ? 'active' : ''"
          >
            <template #icon>
              <Icon icon="material-symbols-light:percent" />
            </template>
            <template #list>
              <ul class="nav">
                <SidenavItem v-if="authStore.hasPermission(['Gutscheine Einsehen', 'Gutscheine Bearbeiten'])" to="/vouchers" text="Gutscheine" />
                <SidenavItem v-if="authStore.hasPermission(['Aktionen Einsehen', 'Aktionen Bearbeiten'])" to="/promotions" text="Aktionen" />
                <SidenavItem v-if="authStore.hasPermission(['Aktionen Einsehen', 'Aktionen Bearbeiten'])" to="/promotion-groups" text="Aktionsgruppen" />
                <SidenavItem v-if="authStore.hasPermission(['Aktionen Einsehen', 'Aktionen Bearbeiten'])" to="/code-check" text="Aktionscode Überprüfung" />
              </ul>
            </template>
          </SidenavCollapse>
        </li>

        <li
          v-if="authStore.hasPermission([
            'Umfragen Einsehen',
            'Umfragen Bearbeiten',
            'Newsletter Einsehen',
            'Newsletter Bearbeiten',
            'Aufgabenplanung Einsehen',
            'Aufgabenplanung Bearbeiten',
            'Support-Tickets einsehen',
            'Support-Tickets bearbeiten',
            'Support-Kategorien bearbeiten',
            'Support-Kategorien einsehen',
            'Support-Templates einsehen',
            'Support-Templates bearbeiten',
            'Kunden Einsehen',
            'Kunden Bearbeiten',
            'Mitgliedschaften Einsehen',
            'Mitgliedschaften Bearbeiten',
          ])" class="nav-item"
        >
          <hr class="mt-2 mb-2 horizontal dark">
        </li>
        <li class="nav-item"><Headline class="text-sm mb-0 fw-normal nav-link">CRM</Headline></li>
        <li
          v-if="authStore.hasPermission([
            'Kunden Einsehen',
            'Kunden Bearbeiten',
            'Mitgliedschaften Einsehen',
            'Mitgliedschaften Bearbeiten',
          ])" class="nav-item"
        >
          <SidenavCollapse :link-amount="3" collapse-ref="users" :is-expanded="['user-groups', 'users', 'membership-types'].includes(getRoute)" nav-text="Kunden" :class="['user-groups', 'users', 'membership-types'].includes(getRoute) ? 'active' : ''">
            <template #icon>
              <Icon icon="material-symbols-light:user-attributes-outline-rounded" />
            </template>
            <template #list>
              <ul class="nav">
                <SidenavItem v-if="authStore.hasPermission(['Kunden Einsehen', 'Kunden Bearbeiten'])" to="/users" text="Übersicht" />
                <SidenavItem v-if="authStore.hasPermission(['Kunden Einsehen', 'Kunden Bearbeiten'])" to="/user-groups" text="Zielgruppen" />
                <SidenavItem v-if="authStore.hasPermission(['Mitgliedschaften Einsehen', 'Mitgliedschaften Bearbeiten'])" to="/membership-types" text="Mitgliedschaften" />
              </ul>
            </template>
          </SidenavCollapse>
        </li>
        <li
          v-if="authStore.hasPermission([
            'Umfragen Einsehen',
            'Umfragen Bearbeiten',
            'Newsletter Einsehen',
            'Newsletter Bearbeiten',
            'Aufgabenplanung Einsehen',
            'Aufgabenplanung Bearbeiten',
          ])"
          class="nav-item"
        >
          <SidenavCollapse :link-amount="3" collapse-ref="event-dates" :is-expanded="['tasks', 'newsletters'].includes(getRoute)" nav-text="Marketing" :class="['tasks', 'newsletters'].includes(getRoute) ? 'active' : ''">
            <template #icon>
              <Icon icon="material-symbols-light:spatial-audio-off-outline-rounded" />
            </template>
            <template #list>
              <ul class="nav">
                <SidenavItem v-if="authStore.hasPermission(['Umfragen Einsehen', 'Umfragen Bearbeiten'])" to="/surveys" text="Umfragen" />
                <SidenavItem v-if="authStore.hasPermission(['Newsletter Einsehen', 'Newsletter Bearbeiten'])" to="/newsletters" text="Newsletter" />
                <SidenavItem v-if="authStore.hasPermission(['Aufgabenplanung Einsehen', 'Aufgabenplanung Bearbeiten'])" to="/tasks" text="Aufgabenplanung" />
              </ul>
            </template>
          </SidenavCollapse>
        </li>
        <li
          v-if="authStore.hasPermission([
            'Support-Tickets einsehen',
            'Support-Tickets bearbeiten',
          ])"
          class="nav-item"
        >
          <SidenavCollapse :link-amount="4" collapse-ref="settings" :is-expanded="['support'].includes(getRoute)" nav-text="Q&S Ticket System" :class="['support'].includes(getRoute) ? 'active' : ''">
            <template #icon>
              <Icon icon="material-symbols-light:settings-input-component-outline-rounded" />
            </template>
            <template #list>
              <ul class="nav">
                <SidenavItem v-if="authStore.hasPermission(['Support-Tickets einsehen', 'Support-Tickets bearbeiten'])" to="/support" text="E-Mail Tickets" />
              </ul>
            </template>
          </SidenavCollapse>
        </li>
      </NuxtScrollbar>
      <NuxtScrollbar :options="{ wheelSpeed: 0.25, swipeEasing: true, wheelPropagation: false, scrollYMarginOffset: 1 }" tag="ul" class="h-100 navbar-nav navbar-nav-right pb-4" :class="{ active: activeSidenavTab === 2 }">
        <li class="nav-item"><h4 class="fw-semibold text-sm mb-0 fw-normal nav-link">Mehr Funktionen</h4></li>
        <li
          v-if="authStore.hasPermission([
            'Mitarbeiter Einsehen',
            'Mitarbeiter Bearbeiten',
            'Berechtigungen Einsehen',
            'Berechtigungen Bearbeiten',
          ])"
          class="nav-item"
        >
          <SidenavCollapse :link-amount="3" collapse-ref="admins" :is-expanded="['admin-groups', 'admins', 'roles'].includes(getRoute)" nav-text="Mitarbeiter" :class="['admin-groups', 'admin', 'roles'].includes(getRoute) ? 'active' : ''">
            <template #icon>
              <Icon icon="material-symbols-light:manage-accounts-outline" />
            </template>
            <template #list>
              <ul class="nav">
                <SidenavItem v-if="authStore.hasPermission(['Mitarbeiter Einsehen', 'Mitarbeiter Bearbeiten'])" to="/admins" text="Übersicht" />
                <SidenavItem v-if="authStore.hasPermission(['Berechtigungen Einsehen', 'Berechtigungen Bearbeiten'])" to="/roles" text="Berechtigungen" />
                <SidenavItem v-if="authStore.hasPermission(['Mitarbeiter Einsehen', 'Mitarbeiter Bearbeiten'])" to="/admin-groups" text="Positionen" />
              </ul>
            </template>
          </SidenavCollapse>
        </li>
        <SidenavItem v-if="authStore.hasPermission(['Mandanten Einsehen', 'Mandanten Bearbeiten'])" to="/clients" text="Mandanten" icon="material-symbols-light:user-attributes-outline-rounded" />
        <SidenavItem v-if="authStore.hasPermission(['Shops Einsehen', 'Shops Bearbeiten'])" to="/shops" text="Shops" icon="material-symbols-light:home-work-outline" />
        <SidenavItem v-if="authStore.hasPermission(['Räume Einsehen', 'Räume Bearbeiten'])" to="/rooms" text="Räume" icon="material-symbols-light:meeting-room-outline" />
        <SidenavItem v-if="authStore.hasPermission(['Saalpläne Einsehen', 'Saalpläne Bearbeiten'])" to="/plans" text="Saalpläne" icon="material-symbols-light:table-edit-outline-sharp" />
        <SidenavItem v-if="authStore.hasPermission(['Zahlungsarten Einsehen', 'Zahlungsarten Bearbeiten'])" to="/payments" text="Zahlungsarten" icon="material-symbols-light:credit-card-outline" />
        <SidenavItem v-if="authStore.hasPermission(['Versandarten Einsehen', 'Versandarten Bearbeiten'])" to="/shipping-methods" text="Versandarten" icon="material-symbols-light:delivery-truck-speed-outline" />
        <SidenavItem v-if="authStore.hasPermission(['Preislisten Einsehen', 'Preislisten Bearbeiten'])" to="/prices" text="Preislisten" icon="material-symbols-light:euro" />
        <SidenavItem v-if="authStore.hasPermission(['Auswahllisten Einsehen', 'Auswahllisten Bearbeiten'])" to="/options" text="Auswahllisten" icon="material-symbols-light:list-alt-outline" />
        <li
          v-if="authStore.hasPermission([
            'Support-Einstellungen einsehen',
            'Support-Einstellungen bearbeiten',
          ])"
          class="nav-item"
        >
          <SidenavCollapse :link-amount="4" collapse-ref="settings" :is-expanded="['qs-settings', 'skategorie', 'email-template'].includes(getRoute)" nav-text="Q&S System" :class="['qs-settings', 'skategorie', 'email-template'].includes(getRoute) ? 'active' : ''">
            <template #icon>
              <Icon icon="material-symbols-light:settings-input-component-outline-rounded" />
            </template>
            <template #list>
              <ul class="nav">
                <SidenavItem v-if="authStore.hasPermission(['Support-Einstellungen einsehen', 'Support-Einstellungen bearbeiten'])" to="/qs-settings" text="Einstellungen" />
                <SidenavItem v-if="authStore.hasPermission(['Support-Kategorien einsehen', 'Support-Kategorien bearbeiten'])" to="/skategorie" text="Support Kategorien" />
                <SidenavItem v-if="authStore.hasPermission(['Support-Templates einsehen', 'Support-Templates bearbeiten'])" to="/email-template" text="Automatische Antworten" />
              </ul>
            </template>
          </SidenavCollapse>
        </li>
        <SidenavItem v-if="authStore.hasPermission(['Besteuerung Einsehen', 'Besteuerung Bearbeiten'])" to="/taxes" text="Besteuerung" icon="material-symbols-light:currency-exchange-rounded" />
        <SidenavItem v-if="authStore.hasPermission(['Erlösgruppen Einsehen', 'Erlösgruppen Bearbeiten'])" to="/sales-groups" text="Erlösgruppen" icon="material-symbols-light:nest-thermostat-sensor-eu-outline-rounded" />
        <SidenavItem v-if="authStore.hasPermission(['Datev Export'])" to="/export/export-datev" text="Datev Export" icon="material-symbols-light:download-sharp" />
        <SidenavItem v-if="authStore.hasPermission(['Daten Import'])" to="/import" text="Daten Import" icon="material-symbols-light:upload-sharp" />
        <SidenavItem v-if="authStore.hasPermission(['Drucker'])" to="/printers/" text="Drucker" icon="material-symbols-light:print-outline" />
        <SidenavItem v-if="authStore.hasPermission(['Konfiguration Einsehen', 'Konfiguration Bearbeiten'])" to="/config" text="Konfiguration" icon="material-symbols-light:settings" />
      </NuxtScrollbar>
      <div class="moreFunctions">
        <!--        <div id="chatFunctions" :class="isChatVisible && 'showChatBox'"> -->
        <!--          <div -->
        <!--            class="position-relative" -->
        <!--          > -->
        <!--            <div -->
        <!--              class="overflow-hidden " -->
        <!--            > -->
        <!--              <div @click="toggleChat" class="show-chat d-inline-flex align-items-center  gap-1 text-primary"> -->
        <!--                <Icon icon="material-symbols-light:support-agent-outline-sharp" class="text-lg text-purple " /> Sie brauchen Hilfe ? -->
        <!--              </div> -->
        <!--            </div> -->
        <!--            <div -->
        <!--              @click="hideChat" class="hide-chat" -->
        <!--            > -->
        <!--              <div class="d-inline-flex align-items-center  gap-1 text-primary"> -->
        <!--                <Icon icon="material-symbols-light:support-agent-outline-sharp" class="text-lg text-purple " /> Chat schließen -->
        <!--              </div> -->
        <!--            </div> -->
        <!--          </div> -->
        <!--        </div> -->
        <div class="w-100">
          <a @click="signOut({ callbackUrl: '/login' });" class="cursor-pointer d-inline-flex align-items-center gap-2"><Icon class="text-lg" icon="material-symbols-light:power-settings-new" /> Logout</a>
          <div @click="toggleModes" class="colorSwitch" :class="{ 'dark-mode': colorMode.preference === 'dark' }">
            <ClientOnly>
              <span class="d-inline-flex align-items-center gap-1"><Icon class="text-lg" icon="material-symbols-light:nightlight-outline" /></span>
              <span class="d-inline-flex align-items-center gap-1"><Icon class="text-lg" icon="material-symbols-light:sunny-outline" /></span>
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavItem from "./SidenavItem.vue";
import { useAuthStore } from "~/stores/AuthStore";
import { useDashboards } from "~/composables/useDashboards";

const isChatVisible = useState("isChatVisible");
const { fetchDashboards, dashboardsCount } = useDashboards();
await fetchDashboards();
const { data: signOut } = useAuth();
const client = useState("client");
const authStore = useAuthStore();
const activeSidenavTab = ref(1);
const settingsPageRoutes = [
  "clients",
  "shops",
  "rooms",
  "plans",
  "payments",
  "shippings-methods",
  "prices",
  "options",
  "taxes",
  "sales-groups",
  "admins",
  "roles",
  "admin-groups",
  "export",
  "config",
];

const colorMode = useColorMode();

function setLightMode() {
  colorMode.preference = "light";
}

function setDarkMode() {
  colorMode.preference = "dark";
}
function toggleModes() {
  if (colorMode.preference === "dark") setLightMode();
  else setDarkMode();
}

function toggleChat() {
  isChatVisible.value = !isChatVisible.value;
}

function hideChat() {
  isChatVisible.value = false;
}

const getRoute = computed(() => {
  const route = useRoute();
  return route.path.split("/")[1];
});

if (settingsPageRoutes.includes(getRoute.value)) activeSidenavTab.value = 2;
</script>
